import styled from "styled-components";
import { BP, Rem } from "../../commons/Theme";

export const StyledParagraphWithCTA = styled.div`
  @media (${BP.ipad}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const StyledParagraphWithCTAImage = styled.img`
  width: 100%;
  max-width: ${Rem(582)};
  margin-bottom: ${Rem(46)};
`;

export const StyledParagraphWithCTAContent = styled.div``;

export const StyledParagraphWithCTAParagraph = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
  padding-bottom: ${Rem(12)};
`;

export const StyledParagraphWithCTACTA = styled.div`
  display: inline-block;
`;
