import React, { useContext } from "react";
import {
  StyledParagraphWithCTA,
  StyledParagraphWithCTAContent,
  StyledParagraphWithCTACTA,
  StyledParagraphWithCTAParagraph,
} from "./style.jsx";
import Button from "../Button/Button";
import Icon, { icons } from "../Icon/Icon";
import { StyledGridRow } from "../../commons/Grid";
import { DataContext } from "../../contexts/DataContext";

export default ({ paragraphs, cta }) => {
  const { i18nLink } = useContext(DataContext);

  return (
    <StyledGridRow>
      <StyledParagraphWithCTA>
        <StyledParagraphWithCTAContent>
          {paragraphs.map((paragraph, index) => (
            <StyledParagraphWithCTAParagraph
              key={index}
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
          ))}
          <StyledParagraphWithCTACTA>
            <Button as="div" noPadding theme="white" to={cta.url ? i18nLink(`${cta.url}`) : null}>
              {cta.label} <Icon icon={icons.arrowRight} />
            </Button>
          </StyledParagraphWithCTACTA>
        </StyledParagraphWithCTAContent>
      </StyledParagraphWithCTA>
    </StyledGridRow>
  );
};
